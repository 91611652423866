/* eslint-disable fp/no-let */
/* eslint-disable no-magic-numbers */
// import { ProductContainerState } from '@scandipwa/scandipwa/src/component/Product/Product.type';
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated-typescript';

import {
    ProductCardComponentProps,
    ProductCardContainerPropKeys,
    ProductCardContainerProps,
} from 'Component/ProductCard/ProductCard.type';
import SharedTransitionContainer, {
    SharedTransitionUnstated,
} from 'Component/SharedTransition/SharedTransition.unstated';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import { ProductContainer } from 'SourceComponent/Product/Product.container';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';
import { getSmallImage } from 'SourceUtil/Product/Extract';

import ProductCard from './ProductCard.component';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Inov8/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    // TODO implement logic

    static defaultProps: Partial<ProductCardContainerProps> = {
        ...ProductContainer.defaultProps,
        swatches: true,
        reviewsStars: true,
        wishlistBtn: true,
        addToCartBtn: true,
        quickAddBtn: true,
        compareBtn: true,
        shortDescription: true,
        isGendered: false,
        gender: 0,
        layout: window.innerWidth <= 768 ? CategoryPageLayout.LIST : CategoryPageLayout.GRID,
        isProductLinks: false,
    };

    componentDidMount() {
        // this.setInitialLayout(); // Set the initial layout
        this.adjustTitleHeights();
        // window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        this.adjustTitleHeights();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustTitleHeights);
    }

    adjustTitleHeights = () => {
        // Logic to adjust title heights
        const titles = document.getElementsByClassName('ProductCard-Name');
        // eslint-disable-next-line fp/no-let
        let maxHeight = 0;

        // Find the max height
        Array.from(titles).forEach((title) => {
            const element = title as HTMLElement; // Type assertion
            maxHeight = Math.max(maxHeight, element.offsetHeight);
        });

        // Set all titles to the max height
        Array.from(titles).forEach((title) => {
            const element = title as HTMLElement; // Type assertion
            element.style.height = `${maxHeight}px`;
        });
    };

    containerProps(): Pick<ProductCardComponentProps, ProductCardContainerPropKeys> {
        const {
            children,
            mix,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            renderContent,
            product,
            isPlp,
            onLoad,
            isProductLinks,
        } = this.props;
        let {layout} = this.props;

        if (isProductLinks) {
            layout = CategoryPageLayout.GRID;
        }

        return {
            ...super.containerProps(),
            children,
            hideCompareButton,
            hideWishlistButton,
            isLoading,
            layout,
            mix,
            renderContent,
            isPlp,
            thumbnail: getSmallImage(this.getActiveProduct()) || getSmallImage(product),
            linkTo: this.getLinkTo(),
            onLoad,
        };
    }

    render(): ReactElement {
        const {
            // eslint-disable-next-line max-len
            swatches,
            reviewsStars,
            wishlistBtn,
            addToCartBtn,
            quickAddBtn,
            compareBtn,
            shortDescription,
            isGendered,
            gender,
            product,
            isProductLinks,
        } = this.props;

        // @ts-ignore
        const productThumbnail = product?.productThumbnail;

        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }: SharedTransitionUnstated): JSX.Element => (
                    <ProductCard
                      { ...this.containerFunctions }
                      { ...this.containerProps() }
                      compareBtn={ compareBtn }
                      wishlistBtn={ wishlistBtn }
                      swatches={ swatches }
                      reviewsStars={ reviewsStars }
                      shortDescription={ shortDescription }
                      addToCartBtn={ addToCartBtn }
                      quickAddBtn={ quickAddBtn }
                      registerSharedElement={ registerSharedElement }
                      productThumbnail={ productThumbnail }
                      isGendered={ isGendered }
                      gender={ gender }
                      isProductLinks={ isProductLinks }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
