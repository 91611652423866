/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-dom-props */
import { PureComponent } from 'react';

import AniTextIconBtn from 'Component/AniTextIconBtn/AniTextIconBtn.component';
import RightArrow from 'Component/RightArrow/RightArrow.component';

import { AddToCartComponentProps } from './AddToCart.type';

import './AddToCart.style';

/** @namespace Inov8/Component/AddToCart/Component */
export class AddToCartComponent extends PureComponent<AddToCartComponentProps> {
    state = {
        isCheckboxChecked: false,
    };

    onCheckboxChange = (event: { target: { checked: any } }) => {
        this.setState({ isCheckboxChecked: event.target.checked });
    };

    renderPreorderCheckbox() {
        const { isPreorder } = this.props;

        if (!isPreorder) {
            return null;
        }

        return (
            <div className="preorder-checkbox-container">
                <label className="PreorderCheckbox">
                    <input
                      type="checkbox"
                      id="preorderCheckbox"
                      name="preorder"
                      onChange={ this.onCheckboxChange }
                    />
                    <span>I am aware this is a pre-order item</span>
                </label>
            </div>
        );
    }

    render() {
        const {
            handleButtonClick,
            blockColor,
            isPreorder,
        } = this.props;
        const { isCheckboxChecked } = this.state;

        const buttonText = isPreorder ? 'PREORDER' : 'ADD TO CART';
        const isButtonDisabled = isPreorder && !isCheckboxChecked;

        return (
            <div className="AddToCartWrapper">
                { this.renderPreorderCheckbox() }
                <AniTextIconBtn
                  text={ buttonText }
                  block={ blockColor }
                  onClick={ isButtonDisabled ? undefined : handleButtonClick }
                  disabled={ isButtonDisabled }
                  icon={ isPreorder ? null : <RightArrow /> }
                />
            </div>
        );
    }
}

export default AddToCartComponent;
