/* eslint-disable prefer-destructuring */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';
import { Dispatch } from 'redux';

// import { AddToCartComponent } from 'Component/AddToCart/AddToCart.component';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { CategoryPageLayout } from 'SourceRoute/CategoryPage/CategoryPage.config';
import { NotificationType } from 'SourceStore/Notification/Notification.type';
import {
    getName,
    getProductInStock,
} from 'SourceUtil/Product/Extract';
import { StockCheckProduct } from 'SourceUtil/Product/Product.type';
import { RootState } from 'Util/Store/Store.type';

import AddToCartComponent from './AddToCart.component';
import {
    AddToCartComponentContainerPropKeys,
    AddToCartComponentProps,
    AddToCartContainerProps,
} from './AddToCart.type';
/** @namespace Inov8/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});
/** @namespace Inov8/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});
/** @namespace Inov8/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static defaultProps: Partial<AddToCartContainerProps> = {
        quantity: 1,
        cartId: '',
        mix: {},
        layout: CategoryPageLayout.GRID,
        isIconEnabled: true,
        isDisabled: false,
        addToCart: undefined,
        updateSelectedValues: undefined,
        withLink: false,
        product: {},
        blockColor: 'primary',
    };

    containerProps(): Pick<AddToCartComponentProps, AddToCartComponentContainerPropKeys> {
        const {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            blockColor,
            product,
        } = this.props;

        const {
            isAdding,
        } = this.state;

        const isPreorder = product.attributes?.is_preorder?.attribute_value === '1'; // or simply `product.attributes?.is_preorder?.attribute_value` if it's a boolean
        const preorderMessage = product.attributes?.preorder_message?.attribute_value || 'This is a preorder item';

        return {
            isDisabled,
            isIconEnabled,
            mix,
            layout,
            isAdding,
            blockColor,
            isPreorder,
            preorderMessage,
        };
    }

    validateStock(): boolean {
        const { product, showNotification } = this.props;
        const inStock = getProductInStock(product as Partial<StockCheckProduct>);

        if (product.sku === 'ECOM-SUB-Platinum-Monthly') {
            return true;
        }

        if (!inStock) {
            const name = getName(product);

            showNotification(NotificationType.INFO, __('Sorry! The product %s is out of stock!', name));
        }

        return inStock;
    }

    render(): ReactElement {
        return (
            <AddToCartComponent
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default (AddToCartContainer);
